export interface Content {
    meta: {},
    posts: {},
}

export interface Image {
    path: string;
}

export interface Post {
    title: string;
    date: string;
    content: string;
    image: Image;
}

export interface Posts {
    fields: any;
    entries: Post[];
    total: number;
}

let basePath = 'https://gewinnermusik.de/cp/api';
let token = `1fef1f83b4e50a6b06b2577541b634`;

export default async function content(): Promise<Content> {

    const posts = await fetch(
        `${basePath}/collections/get/trapez_posts?token=${token}`,
        {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                filter: { published: true },
                limit: 6,
                sort: { date: -1 },
            }),
        },
    ).then(res => {
        return (res.json() as unknown) as Posts;
    });

    const meta = await fetch(
        `${basePath}/singletons/get/trapez_meta?token=${token}`,
        {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
        },
    ).then(res => {
        return res.json();
    });

    return {
        posts,
        meta,
    };

}
