import { BehaviorSubject, fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, map, tap } from "rxjs/operators";

interface SectionOptions extends DOMStringMap {
  title: string;
  navTitle?: string;
}

interface Section {
  index: number;
  section: HTMLElement;
  navItem: HTMLAnchorElement;
  box: ClientRect;
  options: SectionOptions;
}

interface SectionSignal {
  emitter: "nav" | "scroll";
  index: number;
}

interface SectionSignal {
  emitter: "nav" | "scroll";
  index: number;
  section?: Section;
}

export function section() {
  const baseClass = ".js-section";
  const navClass = ".js-section_nav";

  const nav = document.querySelector(navClass);

  // setup sections object
  const sections: Section[] = Array.from(
    document.querySelectorAll<HTMLElement>(baseClass)
  ).map((section, index) => {
    const options = section.dataset as SectionOptions;
    const { title, navTitle } = options;

    const navItem = document.createElement("a");
    navItem.textContent = navTitle || title;
    navItem.setAttribute("title", navTitle || title);
    navItem.classList.add(
      `${baseClass}__nav-item`,
      `--${title.toLowerCase().replace(/\s/g, "-")}`
    );
    navItem.addEventListener("click", event => {
      event.preventDefault();
      navigateToSection(index);
    });

    nav.appendChild(navItem);

    return {
      index,
      section,
      navItem,
      box: section.getBoundingClientRect(),
      options
    };
  });

  // setup module stream
  const ui$ = new BehaviorSubject<number>(0);

  // dispatch ui$ behavior
  function navigateToSection(index: number) {
    ui$.next(index);
  }

  const scrollOffset = 300;
  const scroll$ = fromEvent<Event>(window, "scroll").pipe(
    debounceTime(30),
    map<Event, SectionSignal>(() => {
      const sectionBoxes: any[] = sections.reduce(
        (starts, { box, section }) => [
          ...starts,
          {
            box: section.getBoundingClientRect(),
            height: section.offsetHeight,
            top: section.offsetTop
          }
        ],
        []
      );
      return sectionBoxes.reduce(
        (signal, { box }, index) => {
          const viewBottom = window.innerHeight - scrollOffset;
          const topIn = viewBottom > box.top && box.top > 0;
          const bottomIn = viewBottom > box.bottom && box.bottom > 0;
          const cover = box.top < 0 && box.bottom > viewBottom;

          if (topIn || bottomIn || cover) return { index, emitter: "scroll" };
          return signal;
        },
        { emitter: "scroll", index: 0 }
      );
    }),
    distinctUntilChanged((a, b) => a.index === b.index)
  );

  // scroll$.subscribe();

  // handle ui$
  merge<SectionSignal>(
    ui$.pipe(map(index => ({ emitter: "nav", index }))),
    scroll$
  )
    .pipe(
      map((signal: SectionSignal) => ({
        ...signal,
        section: sections[signal.index]
      })),
      tap(v => {
        sections.forEach(({ navItem, section }) => {
          navItem.classList.remove("--is-active");
          section.classList.remove("--is-active");
        });
      })
    )
    .subscribe(({ emitter, section: { navItem, section } }) => {
      navItem.classList.add("--is-active");
      section.classList.add("--is-active");

      if (emitter === "nav") {
        section.scrollIntoView({
          behavior: "smooth"
        });
      }
    });
}
