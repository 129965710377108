import { draw } from './ts/svg';
import { audio } from './ts/audio';
import { section } from './ts/section';
import { posts } from './ts/posts';
import footerContent from './ts/footer-content';

import content from './ts/content';

import './scss/app.scss';

const body = document.querySelector('body');
body.classList.add('--loading');
draw();

content().then(content => {
  setTimeout(() => {
        audio();
        posts(content.posts);
        section();
        footerContent(content.meta);
        body.classList.remove('--loading');
    }, 1200);
});

window.addEventListener('resize', () => {
    draw();
});
