export default async function footerContent(meta) {
    const navItems = document.querySelectorAll<HTMLElement>(
        '.js-footer-link');
    const contentElement = document.querySelector<HTMLElement>(
        '.js-footer-content',
    );

    let currentContent = null;

    const activeToken = '--is-active';

    function updateUi() {
        Array.from(navItems).forEach((item) => {
            item.classList.remove(activeToken);
        });

        if( currentContent === null ) return contentElement.classList.remove(activeToken);

        let currentItem = navItems[currentContent];
        currentItem.classList.add(activeToken);
        contentElement.classList.add(activeToken);
        contentElement.innerHTML = meta[currentItem.dataset.content];
        contentElement.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        });
    }

    Array.from(navItems).forEach((item, index) => {
        item.addEventListener('click', event => {
            event.preventDefault();

            if( currentContent === index ) {
                currentContent = null;
            } else {
                currentContent = index;
            }
            updateUi();
        });
    });

}
