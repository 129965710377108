import { scaleLinear } from "d3-scale";
import Color = require("color");

interface AudioItem {
  index: number;
  item: Element;
  path: string;
  name: string;
  audio: HTMLAudioElement;
}

export function audio() {
  let updateProgress: Function;
  let setCurrent;

  const player = document.querySelector(".player");
  player.addEventListener("mouseout", () => {
    // language=CSS
    document.querySelector("style.root-style").innerHTML = `
            :root {
                --c-bg-default: white;
                --c-bg-light: green;
                --c-bg-contrast: green;
            }
        `;
  });
  const colors = ["#05f", "#ff4000", "#fdff00", "#5f0", "#50f"];
  const items: AudioItem[] = Array.from(
    player.querySelectorAll(".player__item")
  ).map((item: HTMLElement, index) => {
    const path = item.dataset["path"];
    item.dataset["index"] = index.toString();

    const c1 = Color(colors[index])
      .desaturate(0.53)
      .lighten(0.92);

    item.addEventListener("mouseover", () => {
      // language=CSS
      document.querySelector("style.root-style").innerHTML = `
                  :root, svg {
                      --c-bg-default: ${c1};
                      --c-bg-light: ${c1.darken(0.18)};
                      --c-bg-contrast: ${Color(colors[index])
                        .negate()
                        .lighten(0.3)};
                  }  
                `;
    });

    item.addEventListener("click", () => {
      player.dispatchEvent(
        new CustomEvent("play", {
          detail: {
            index: index
          }
        })
      );
    });
    const audio = new Audio(path);
    audio.addEventListener("timeupdate", () => {
      updateProgress();
    });
    audio.addEventListener("ended", () => {
      player.dispatchEvent(
        new CustomEvent("control", {
          detail: {
            action: "next"
          }
        })
      );
    });

    return {
      index,
      item,
      path,
      name: item.innerHTML,
      audio
    };
  });
  const itemsLength = Object.keys(items).length;

  const controls = player.querySelector(".player__controls");
  const info = player.querySelector(".player__controls__info");
  const controlItems = {
    play: document.createElement("div"),
    prev: document.createElement("div"),
    next: document.createElement("div"),
    stop: document.createElement("div")
  };
  const infoItems = {
    title: document.createElement("div"),
    duration: document.createElement("div")
    // currentTime: document.createElement('div'),
  };

  let updateUi: Function;
  let current: AudioItem = items[0];
  current.audio.load();

  setCurrent = (index, force?: boolean) => {
    if (force) {
      current.audio.pause();
      current.audio.currentTime = 0;
      current = items[index];
      current.audio.play();
      return;
    }

    if (!current.audio.paused) {
      current.audio.pause();
      if (index !== current.index) {
        current.audio.currentTime = 0;
        current = items[index];
        current.audio.play();
      }
    } else {
      current.audio.play();
    }
  };

  updateUi = () => {
    const { play } = controlItems;
    const { title, duration } = infoItems;
    // play / pause
    play.classList.add(current.audio.paused ? "--paused" : "--active");
    play.classList.remove(!current.audio.paused ? "--paused" : "--active");
    play.textContent = current.audio.paused ? "play" : "pause";

    // current track
    items.forEach(({ item }) => {
      item.classList.remove("--active");
    });
    current.item.classList.add("--active");
    title.textContent = current.name;
    const minutes = Math.floor(current.audio.duration / 60);
    const seconds = Math.floor(current.audio.duration) % 60;

    duration.textContent = isNaN(current.audio.duration)
      ? "--"
      : `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

    // style
    const c2 = Color(colors[current.index])
      .desaturate(0.8)
      .lighten(0.48);

    document.querySelector("style.player-style").innerHTML = `
                  .player__controls, svg {
                      --c-bg-default: ${c2};
                      --c-bg-light: ${c2.darken(0.18)};
                      --c-bg-contrast: ${Color(colors[current.index])
                        .negate()
                        .lighten(0.3)};
                  }
                `;
  };
  updateUi();

  const progressBar = document.querySelector(".bar") as HTMLDivElement;
  const progressElement = progressBar.parentElement;
  const progressBox = progressElement.getBoundingClientRect();

  updateProgress = () => {
    // const { title, duration } = infoItems;
    const time = current.audio.currentTime;

    const progressScale = scaleLinear()
      .domain([0, current.audio.duration])
      .range([0, 100]);
    const pointerScale = scaleLinear()
      .domain([0, current.audio.duration])
      .range([0, progressBox.width]);

    const pointerHandler = event => {
      current.audio.currentTime = pointerScale.invert(event.offsetX);
    };
    progressElement.removeEventListener("click", pointerHandler);
    progressElement.addEventListener("click", pointerHandler);

    // currentTime.textContent = (time / 60).toFixed(2).toString();
    progressBar.style.width = `${progressScale(time)}%`;
  };

  // setup controls
  Object.entries(controlItems).forEach(([action, element]) => {
    element.classList.add(`_control_${action}`, "_control");
    element.textContent = action;
    element.addEventListener("click", () =>
      player.dispatchEvent(
        new CustomEvent("control", {
          detail: {
            action
          }
        })
      )
    );
    controls.appendChild(element);
  });
  Object.entries(infoItems).forEach(([name, element]) => {
    element.classList.add(`_info_${name}`, "_info");
    info.appendChild(element);
  });

  // setup player to monitor clicks
  player.addEventListener("play", ({ detail: { index } }: CustomEvent) => {
    setCurrent(current === index ? null : index);
    updateUi();
  });
  player.addEventListener("control", ({ detail: { action } }: CustomEvent) => {
    const { audio, index } = current;
    switch (action) {
      case "play":
        audio.paused ? audio.play() : audio.pause();
        break;
      case "prev":
        setCurrent(index > 0 ? index - 1 : itemsLength - 1, true);
        break;
      case "next":
        setCurrent(index < itemsLength - 1 ? index + 1 : 0, true);
        break;
      case "stop":
        audio.pause();
        audio.currentTime = 0;
        break;
    }
    updateUi();
  });
}
