import { path } from "d3-path";
import { scaleLinear } from "d3-scale";

export function draw() {
  const element: Element = window.document.scrollingElement;
  const svg = element.querySelector("[data-decoration]");
  const view = svg.getBoundingClientRect();
  const { width, height } = view;
  const margin = 40;
  const points = [
    { x: margin, y: margin },
    { x: width - margin, y: margin },
    { x: width - margin, y: height - margin },
    { x: margin, y: height - margin }
  ];

  const drawTrapez = (updatePoints: { x; y }[]) => {
    const trapez = path();

    updatePoints.forEach(({ x, y }, index) => {
      index === 0 ? trapez.moveTo(x, y) : trapez.lineTo(x, y);
    });
    trapez.closePath();

    svg.innerHTML = `<path d="${trapez.toString()}" />`;
  };
  drawTrapez(points);

  const sectorScaleX = scaleLinear()
    .domain([0, width])
    .range([-1, 1]);
  const sectorScaleY = scaleLinear()
    .domain([0, height])
    .range([-1, 1]);
  let sector: 0 | 1 | 2 | 3 = 0;
  let listener = ({ clientX, clientY }: MouseEvent) => {
    const offset = { x: sectorScaleX(clientX), y: sectorScaleY(clientY) };

    if (offset.x < 0 && offset.y < 0) sector = 0;
    if (offset.x >= 0 && offset.y < 0) sector = 1;
    if (offset.x >= 0 && offset.y >= 0) sector = 2;
    if (offset.x < 0 && offset.y >= 0) sector = 3;

    const update = points.map(({ x, y }, index) => {
      return {
        x: x + margin * offset.x * (index === sector ? 0.1 : 1),
        y: y + margin * offset.y * (index === sector ? 0.1 : 1)
      };
    });
    drawTrapez(update);
  };
  element.removeEventListener("mousemove", listener);
  element.addEventListener("mousemove", listener);
}
