// language=HTML
import { Image } from './content';

const template = (
    title: string,
    date: string,
    content: string,
    image?: Image
): string => `
    <div class="post  ${image && 'post--image'}">
        <div class="post__content">
          <h3 class="post__title">${title}</h3>
          <p class="post__date">${date}</p>
          <p>${content}</p>
        </div>
        ${
            image
                ? `<img src="https://gewinnermusik.de/${image.path}" alt="${title}">`
                : ''
        }
    </div>
`;

export async function posts(posts) {
    // render posts
    const postWrapper = document.querySelector('.js-posts');

    posts.entries.forEach(({ title, date, content, image }) => {
        postWrapper.innerHTML += template(title, date, content, image);
    });

    return new Promise(resolve => resolve(posts));
}
